<script setup>
import TeammateFilter from './partials/teammate-filter';
import TeammateCard from './partials/teammate-card';

const props = defineProps({
  data: {
  },
  fetchData: {
    type: Function,
    required: true,
  },
});
const metaStore = useMetaStore();
const layoutStore = useLayoutStore();

const teammate = ref([]);
const filteredTeammate = ref([])
const pageData = ref(props.data);

teammate.value = await useSsrFetch(`pages/teammate`);
const meta = ref({});
meta.value = teammate.value.meta
metaStore.setMeta({ ...meta.value });


teammate.value = await layoutStore.fetchTeammate();
filteredTeammate.value = teammate.value;

const filterTeammate = ({ cityId, langId }) => {
  filteredTeammate.value = teammate.value;

  if (cityId) {
    filteredTeammate.value = filteredTeammate.value.filter(mate => mate.city_id === cityId);
  }
  if (langId) {
    filteredTeammate.value = filteredTeammate.value.filter(mate => mate.supported_languages.some(l => l._id == langId));
  }
};

const settingStore = useSettingsStore()
const whatsapp = settingStore.settings.social_media_whatsapp;
</script>

<template>
  <LayoutAppHead></LayoutAppHead>
  <NuxtLayout name="mobile-base">
    <template #mobile-header>
      <mobile-header :title="$t('client.our_team')">
        <template #header-left>
          <mobile-back-button></mobile-back-button>
        </template>
        <template #header-right>
          <div class="flex items-center gap-6 ms-auto">
            <teammate-filter :filterTeammate="filterTeammate"></teammate-filter>
          </div>
        </template>
      </mobile-header>
    </template>

    <div class="px-4">
      <div v-for="(mate, i) in filteredTeammate" :key="i" class="my-3 border border-b-gray-300 bg-white rounded-lg p-3">
        <teammate-card :mate="mate" :whatsapp="whatsapp"></teammate-card>
      </div>
    </div>
  </NuxtLayout>
</template>